<template>
  <div>
    <pending-warning :pending-properties="pendingProperties"></pending-warning>
    <div v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
      <visit v-for="(visit, index) in visits" :visit="visit" :index="index" :key="visit.id" @event-click="$emit('event-click', $event)" @go-tab="$emit('go-tab', $event)"></visit>
    </div>
    <spinner :show="loading" message="Loading"></spinner>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import Visit from './Visit.vue'
import PendingWarning from '@/shared/pending_warning.vue'
import { dateToAppFormat, onlyUnique } from '@/utils'

export default {
  components: {
    Spinner,
    Visit,
    PendingWarning
  },
  props: {
    client: {
      type: Object
    },
    direction: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    locationId: {
      type: String | Number
    },
    startDate: {
      type: String,
      required: true
    },
    showCancelled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      eof: false,
      loading: true,
      limit: 5,
      offset: 0,
      visits: []
    }
  },
  computed: {
    params() {
      var params = {
        start_date: this.startDate,
        end_date: this.endDate,
        location_id: this.locationId,
        show_cancelled: this.showCancelled
      }
      if (this.client) {
        params.client_id = this.client.id
      }
      return params
    },
    pendingProperties() {
      var properties = []
      Array.prototype.forEach.call(this.visits, (visit) => {
        if ((visit.pending && visit.initial) || ((visit.schedule && visit.schedule.pending && !visit.initial))) {
          properties.push(visit.address)
        }
      })

      return properties.filter(onlyUnique)
    }
  },
  methods: {
    reloadAll() {
      this.offset = 0
      this.eof = false
      this.visits = []
      this.loadVisits()
    },
    loadVisits() {
      this.loading = true
      var params = this.params
      params.offset = this.offset * this.limit
      params.limit = this.limit
      params.dir = this.direction
      this.$http.get('/visits', {
        params: this.params
      }).then(response => {
        this.loading = false
        if (response.data.length < this.limit) {
          this.eof = true
        }
        Array.prototype.forEach.call(response.data, (visit) => {
          this.visits.push(visit)
        })
      }, response => {
        this.loading = false
        this.eof = true
        console.error(response.body)
        Flasher.error(response.statusText)
      })
    },
    loadMore() {
      if (this.eof) {
        return
      }
      this.offset += 1
      this.loadVisits()
    },
  },
  mounted() {
    this.reloadAll()
  },
  watch: {
    params(newValue, oldValue) {
      this.reloadAll()
    },
  }
}
</script>
