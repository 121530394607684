<template>
  <div class="row mx-0">
    <div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 px-0">
      <div class="box-wrapper-gray pos-relative manage-prop-cont">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12 border-right-xs-bottom">
            <div class="prop-right-in">
              <div class="">
                <h4 class="visits-box-title">{{visit.full_address}}</h4>
                <p class="gray-text font-14 mb-1">{{ visit.rooms_and_sqft }}</p>
                <div class="mb-1">
                  <div class="d-flex align-self-center">
                    <img width="25" height="25" src="@images/clock-icon.png" alt="">
                    <div class="ml-1">
                      <span class="font-14 font-weight-normal">{{visit.formatted_date}}</span> | <span class="font-14 font-weight-normal">{{visit.duration_time}} arrival</span>
                    </div>
                  </div>
                </div>
                <div class="float-none">
                  <p class="blue-text font-weight-bold font-16 text-center my-0 pt-2">
                    <b-button class="my-0 blue-text font-weight-bold" v-b-toggle="domId+'btns'" role="button" variant="link">
                       View / Edit <br> <i class="fal fa-chevron-down"></i>
                    </b-button>
                  </p>
                  <b-collapse :id="domId+'btns'">
                    <div class="row mb-2 mr-2">
                      <div class="col-6 text-center">
                        <a href="#" class="btn btn-mini btn-primary cursor-pointer my-0" @click.prevent="$emit('event-click', visit.id)">This Visit</a>
                      </div>
                      <div class="col-6 text-center">
                        <a class="btn btn-mini btn-primary cursor-pointer my-0" href="#" @click.prevent="gotoProperty">This Property</a>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 font-14">
            <div class="font-14">
              <p class="font-14 mb-0 font-weight-bold">
                <span :class="cleaningPlanColor">{{visit.selected_plan}}</span>
                <span v-if="cancellationDetails" class="red-text">{{ cancellationInfo }}</span>
              </p>
              <p class="gray-text font-14 my-0 dark-gray-text dark-gray-text">{{visit.schedule_summary}}</p>
              <p v-if="isAdmin" class="gray-text font-14 my-0 dark-gray-text dark-gray-text">{{visit.assigned}}</p>
              <p class="gray-text font-14 mt-0 dark-gray-text dark-gray-text mb-4">Reference ID: {{referenceID}}</p>
              <visit-breakdown :visit="visit" :dom-id="domId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VisitBreakdown from '@/shared/visit_breakdown.vue'
import cancellation_info_mixin from '@/shared/cancellation_info_mixin'
export default {
  components: { VisitBreakdown },
  props: ['visit', 'index'],
  mixins: [ cancellation_info_mixin ],
  computed: {
    cancellationDetails() {
      if (this.visit.cancellation_details && Object.keys(this.visit.cancellation_details).length != 0) {
        return this.visit.cancellation_details
      }
      return null
    },
    domId() {
      return "visit_" + this.visit.id + '_' + this.index;
    },
    canChangeGcalStatus() {
      if (this.isStaff) {
        return true
      }
      return false
    },
    cleaningPlanColor() {
      if(this.visit.initial) {
         return 'green-text'
      } else  {
         return 'blue-text'
      }
      return 'text-gray'
    },
    referenceID() {
      if (this.visit) {
        return this.visit.reference_id
      }
    },
  },
  methods: {
    gotoProperty() {
      if (window.location.pathname == '/calendar') {
        window.location = this.visit.manage_url
      } else {
        this.$emit('go-tab', 0)
      }
    }
  }
}
</script>
